<template>
  <div class="programManagement">
    <div class="search_Info">
      <div>
        名称: &nbsp;<a-input
          placeholder="输入名称搜索"
          style="width: 180px"
          size="default"
          v-model="searchName"
        />&nbsp;&nbsp;&nbsp;
        <a-button style="margin-left: 10px" type="primary" @click="search"
          >查询</a-button
        >
        <a-button style="margin-left: 10px" @click="clear">重置</a-button>
      </div>
    </div>
    <div class="table_Info">
      <a-button type="primary" @click="add">新增</a-button>
      <a-table
        style="min-height: 500px; margin-top: 10px"
        :columns="columns"
        :data-source="typeList"
        :pagination="false"
      >
        <template slot="index" slot-scope="text, row, index">
          {{ index + (pageNo - 1) * pageSize + 1 }}
        </template>
        <template slot="operation" slot-scope="text, row, index">
          <span
            v-if="
              index == typeList.length - 1 &&
              pageNo == Math.ceil(total / pageSize)
            "
          >
            <a style="margin-left: 10px" @click="move('up', row.id)">上移</a>
          </span>
          <span v-else-if="index == 0 && pageNo == 1">
            <a style="margin-left: 10px" @click="move('down', row.id)">下移</a>
          </span>
          <span v-else>
            <a style="margin-left: 10px" @click="move('up', row.id)">上移</a>
            <a style="margin-left: 10px" @click="move('down', row.id)">下移</a>
          </span>
          <a @click="edit(row)" style="margin-left: 10px">修改</a>&ensp;
          <a-popconfirm
            title="是否确定删除?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="del(row.id)"
            @cancel="delCancel"
          >
            <a href="#" style="margin-left: 10px">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
      <div
        style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ total }}条记录 第 {{ pageNo }} /
          {{ Math.ceil(total / pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pageSize"
          :total="total"
          v-model="pageNo"
          @change="page2Change"
          @showSizeChange="page2Change"
        />
      </div>
    </div>
    <a-modal
      :visible="visibleModal"
      @ok="handleModal"
      @cancel="cancel"
      title="新增/修改 分类"
    >
      <a-form-model-item label="名称">
        <a-input
          placeholder="请输入"
          style="width: 290px"
          v-model="title"
          size="default"
        />
      </a-form-model-item>
      <a-form-model-item label="code值">
        <a-input
          placeholder="请输入"
          style="width: 290px"
          v-model="code"
          size="default"
        />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import {
  getType1,
  addType,
  delType,
  editType,
  moveType,
} from "@/service/MedicalFormulas_api";
export default {
  name: "programManagement",
  data() {
    return {
      typeList: [],
      searchName: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
      visibleModal: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      otherList: [],
      title: "",
      code: "",
      type: "add",
      id: "",
      columns: [
        {
          title: "序号",
          align: "center",
          width: "10%",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "名称",
          dataIndex: "title",
          align: "center",
          width: "20%",
        },
        {
          title: "code值",
          dataIndex: "code",
          align: "center",
          width: "20%",
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    async load() {
      let data = {
        title: this.searchName,
      };
      const res = await getType1(this.pageNo, this.pageSize, data);
      if (res.code == 0) {
        this.typeList = res.data;
        this.total = res.count;
      }
    },
    add() {
      this.visibleModal = true;
    },
    search() {
      this.pageNo = 1;
      this.load();
    },
    clear() {
      this.searchName = "";
      this.load();
    },
    async handleModal() {
      if (this.title != "") {
        if (this.type == "add") this.addOk();
        if (this.type == "edit") this.editOk();
      } else {
        this.$message.warn("名称不能为空！");
      }
    },
    cancel() {
      this.visibleModal = false;
      this.title = "";
      this.title = "";
      this.code = "";
      this.type = "add";
    },
    async del(id) {
      const res = await delType(id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.load();
      }
    },
    delCancel() {},
    edit(row) {
      this.id = row.id;
      this.visibleModal = true;
      this.title = row.title;
      this.code = row.code;
      this.type = "edit";
    },
    async addOk() {
      let data = {
        title: this.title,
        code: this.code,
      };
      const res = await addType(data);
      if (res.code == 0) {
        this.$message.success("添加成功！");
        this.load();
        this.visibleModal = false;
        this.title = "";
        this.code = "";
      } else {
        this.$message.warn(res.message);
      }
    },
    async editOk() {
      let data = {
        title: this.title,
        id: this.id,
        code: this.code,
      };
      const res = await editType(data);
      if (res.code == 0) {
        this.$message.success("修改成功！");
        this.title = "";
        this.code = "";
        this.type = "add";
        this.visibleModal = false;
        this.load();
      } else {
        this.$message.warn(res.message);
      }
    },
    page2Change(pageNo, pageSize) {
      this.pageNo = pageNo;
      this.pageSize = pageSize;
      this.load();
    },
    async move(type, id) {
      if (type == "up") {
        const res = await moveType(type, id);
        if (res.code == 0) {
          this.$message.success("移动成功！");
          this.load();
        }
      }
      if (type == "down") {
        const res = await moveType(type, id);
        if (res.code == 0) {
          this.$message.success("移动成功！");
          this.load();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.programManagement {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .search_Info {
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .table_Info {
    height: 100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    background-color: white;
  }
}
</style>
